import crops from "./modules/crops";
import livestock from "./modules/livestock";
import poultry from "./modules/poultry";
import farmerfarms from "./modules/farmerfarms";

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueToast from "vue-toast-notification";

Vue.use(Vuex);
Vue.use(VueToast, {
  position: "top",
});

const store = new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: "",
    drawer: false,
    login: false,
    user: [],
    registration:false,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    async loginState(state, payload) {
      state.login = payload;
    },
    setLoginUser(state, payload) {
      state.user = payload;
    },
    clearLoginUser(state) {
      state.user = [];
    },
    registrationStatus(state,payload)
    {
      state.registration=payload
    }
  },
  actions: {
    async loginUser({ commit, dispatch }, payload) {
       await axios.get("sanctum/csrf-cookie").then(async () => {
        await axios
          .post("login", payload)
          .then((res) => {
            if (
              res.status >= "200" &&
              res.status <= "204" &&
              payload.robot == false
            ) {
             
              dispatch("getUser", payload.email);
            }
          })
          .catch((err) => {
            this.$toast.error("Incorrect Credentials");
          });
      });
    },

    async getUser({ commit }, payload) {
      axios.get("api/user/" + payload).then((res) => {
          if(!res.data.email_verified_at){
            this.$toast.error("Please verify your account");
          }else{
            commit("setLoginUser", res.data);
            commit("loginState", true);
          }
      });
    },

    async logoutUser({ commit }) {
      commit("loginState", false);
      commit("clearLoginUser");
    },

    async registerUser({commit},payload){
      await axios.post('register',payload).then((res)=>{
        if(res.status>='200' && res.status<="204"){
          commit('registrationStatus',true)
        }else{
          commit('registrationStatus',false)
        }
      }).catch(()=>{
        this.$toast.error("Failed Registration")
      })
    },

      async clearRegistration({commit}){
        commit('registrationStatus',false)
      }
  },

  modules: {
    crops: crops,
    livestock: livestock,
    poultry: poultry,
    // muncity:muncity,
    // authuser:authuser,
    farmerfarms: farmerfarms,
  },
});

export default store;
